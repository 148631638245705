// CSS DEBUGGER
// *:not(path):not(g) {
//   color: hsla(210, 100%, 100%, 0.9) !important;
//   background: hsla(210, 100%, 50%, 0.5) !important;
//   outline: solid 0.25rem hsla(210, 100%, 100%, 0.5) !important;
//   box-shadow: none !important;
// }

@import url('https://fonts.googleapis.com/css2?family=Lusitana&family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: $bgcolor;
  overflow-x: hidden;
  // font-family: 'Lusitana', serif;
  font-family: $font-sans-serif;
  font-weight: 400;
  @include fp(font-size, 10, 12);
  @include fp(line-height, 12, 15);
  color: $black;
}

b,
strong {
  font-weight: 700;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1 {
  font-family: $font-serif;
  @include fp(font-size, 18, 24);
  @include fp(line-height, 25, 31);
  font-weight: 400;
}

h2 {
  font-family: $font-sans-serif;
  @include fp(font-size, 14, 18);
  @include fp(line-height, 19, 23);
  font-weight: 400;
}

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}
