@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

// Fluid properties
// Usage: @include fp(font-size, 50, 100); //50px at 320, 100px at 1192;
@mixin fp(
  $property,
  $min,
  $max,
  $start: 320,
  $end: 1192,
  $clip: true,
  $clipAtStart: true,
  $clipAtEnd: true
) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}

// Viewport Mixins Usage:
// .element {
//   @include bp-* {
//     property: value;
//   }
// }

@mixin bp-md {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin bp-lg {
  @media only screen and (min-width: 1192px) {
    @content;
  }
}

@mixin bp-xl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}



// Default time and ease for transitions
@mixin transition-default {
  transition: all 0.1s ease-in-out;
}

// Set a rem font size with pixel fallback
@function rem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: rem($size);
}
