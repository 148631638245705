// FONTS
$font-serif: 'Lusitana', serif;
$font-sans-serif: 'PT Sans', sans-serif;

// COLORS
$white: hsl(0, 100%, 100%);
$black: hsl(0, 0%, 10%);
$transparent: hsla(0, 0%, 0%, 0);
$bgcolor: hsla(0, 0%, 90%, 1);
$brown: hsla(12, 40%, 32%, 1);
$orange: hsla(16, 37%, 47%, 1);

