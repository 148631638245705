.main {
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  @include fp(padding, 20, 50);

  .shape {
    position: absolute;
    top: 75px;
    left: 0;
    height: 250px;
    width: 250px;
    background: $transparent;
    border: .5px solid hsla(12, 40%, 32%, 0.5);
    animation: morph 10s ease-in-out infinite;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    transition: all 1s ease-in-out;
    transform: translateX(-40%);
    z-index: -1;

    @include bp-lg {
      top: 50%;
      height: 500px;
      width: 500px;
      transform: translate(-40%, -50%);
    }
  }
  
  @keyframes morph {
    0% {
        border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
    } 
    
    25% {
        border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
    }
    
    50% {
      border-radius:  50% 30% 40% 50% / 70% 30% 60% 30%;
    }
    
    75% {
        border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
    }
    
    100% {
      border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
    } 
  }

  &__header {
    text-align: center;
    color: $brown;

    i {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 50px;
        background-color: $brown;
        top: 55%;
        left: -70%;
      }

      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 50px;
        background-color: $brown;
        top: 55%;
        right: -70%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    @include bp-lg {
      flex-direction: row;
      align-items: center;
    }

    &__text {
      width: 85%;

      @include bp-lg {
        width: 50%;
        padding-left: 130px;
      }

      h1 {
        color: $brown;
      }

      &__copy {
        @include bp-lg {
          max-width: 400px;
          transform: translateY(-70px);
        }
      }

      &__services {
        position: absolute;
        margin-top: 30px;
        display: flex;
        align-items: center;
        @include fp(font-size, 12, 16);
        @include fp(line-height, 16, 20);
        color: $orange;
        text-transform: uppercase;
        
        @include bp-lg {
          margin-top: 130px;
        }

        &__divider {
          min-width: 50px;
          height: 1px;
          background-color: $orange;
          margin: 0 15px;
        }
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 80px;
      
      @include bp-lg {
        width: 50%;
        margin-top: 0;
      }
      
      &__container {
        position: relative;

        &::after {
          content: "espelho";
          position: absolute;
          @include fp(top, 35, 40);
          @include fp(left, -10, -35);
          color: $orange;
          @include fp(font-size, 26, 32);
          font-weight: bold;
        }

        img {
          max-height: 400px;
        }
      }
    }
  }

  &__sidebar {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100vh;
    @include fp(padding, 20, 50);

    @include bp-lg {
      display: flex;
    }

    &__socials {
      display: flex;
      flex-direction: column;
      margin-top: 1.67em;

      @include bp-lg {
        margin-top: 0;
        align-self: center;
      }

      a {
        text-decoration: none;

        &:not(:first-child) {
          margin-top: 1rem;
          pointer-events: none;
        }
      }

      svg {
        fill: $brown;
        width: 15px;
        height: 15px;
      }
    }

    &__sound {
      position: relative;
      margin-top: 130px;
      
      @include bp-lg {
        margin-top: 0;
        margin-left: -15px;
        align-self: flex-end;
      }

      &::before {
        content: "";
        position: absolute;
        height: 50px;
        width: 1px;
        background-color: $brown;
        top: -70px;
        left: 50%;
      }

      svg {
        fill: $brown;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
  }  
}
